import React, { useState, useContext, useEffect } from 'react';
import * as S from './TubSelectorV4.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import useLocalStorage from '../../hooks/useLocalStorage';
import { getParamFromQuery } from '../../utils/getParamFromQuery';

const TubSelector = props => {
  const {
    tubs,
    defaultOption,
    boxDesign,
    boxDesignMobile,
    showChildren,
    children,
    checkLocalStorage,
    showInnerChild
  } = props;

  const context = useContext(FunnelContext);

  const [currentTubIndex2, setCurrentTubIndex2] = useLocalStorage(
    'currentTubIndex',
    null
  );
  const [selectOnQueryInit, setSelectOnQueryInit] = useState(false);
  const [selectOnQueryIndex, setSelectOnQueryIndex] = useState(-1);

  const {
    currency,
    currentCategory,
    setCurrentProduct,
    tubIndex,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    pageDesign,
    setCurrentTub,
    extraObjects: extraObjectsContext,
    setExtraObjects
  } = context;

  const currencySymbol = currency?.symbol || `$`;

  useEffect(() => {
    if (typeof defaultOption === 'number') {
      setCurrentTubIndex(defaultOption);
      setCurrentProduct(
        currentCategory === `subscriptions`
          ? `subscription_${defaultOption}`
          : `onetime_${defaultOption}`
      );
    }
  }, [currentCategory, setCurrentProduct, defaultOption, setCurrentTubIndex]);

  useEffect(() => {
    const { extraObjects } = tubs[currentTubIndex];

    if (extraObjects) {
      extraObjects.map(({ key, value }) => {
        if (!key) return false;
        const newExtraObjects = Object.assign(extraObjectsContext, {
          [key]: value
        });
        return setExtraObjects(newExtraObjects);
      });
    }

    if (checkLocalStorage) {
      const index = currentTubIndex2 || 0;
      setCurrentTubIndex(index);
      setCurrentProduct(
        currentCategory === `subscriptions`
          ? `subscription_${index}`
          : `onetime_${index}`
      );
    }
  }, [
    tubs,
    currentTubIndex,
    setExtraObjects,
    extraObjectsContext,
    checkLocalStorage,
    currentTubIndex2,
    setCurrentTubIndex,
    setCurrentProduct,
    currentCategory
  ]);

  useEffect(() => {
    if (!selectOnQueryInit) {
      tubs.forEach((tub, index) => {
        const { selectOnQueryKey, selectOnQueryValue } = tub;
        if (
          selectOnQueryKey &&
          selectOnQueryKey !== '' &&
          getParamFromQuery(selectOnQueryKey) === selectOnQueryValue
        ) {
          setTubIndex(index);
          setCurrentTubIndex(index);
          setCurrentTub(tub);
          setCurrentProduct(
            currentCategory === `subscriptions`
              ? `subscription_${index}`
              : `onetime_${index}`
          );
          setSelectOnQueryIndex(index);
        }
      });
      setSelectOnQueryInit(true);
    }
  }, [
    tubs,
    currentCategory,
    setCurrentProduct,
    setCurrentTub,
    setCurrentTubIndex,
    setTubIndex,
    selectOnQueryIndex,
    setSelectOnQueryIndex,
    selectOnQueryInit,
    setSelectOnQueryInit
  ]);

  const TubImage = ({ image, image2, active, alt }) => {
    if (active) {
      switch (pageDesign) {
        case 'design2':
          return <img src={image} alt={alt} width={58} height={58} />;
        case 'design1':
        default:
          return <img src={image2} alt={alt} />;
      }
    } else {
      return <img src={image} alt={alt} width={58} height={58} />;
    }
  };

  const Price = ({ showPrice, index }) => {
    if (!showPrice) return null;
    return (
      <S.Price>
        {currencySymbol}
        <ProductPrice
          type="discounted_price"
          currentCategory={currentCategory}
          currentProduct={
            currentCategory === `onetime`
              ? `onetime_${index}`
              : `subscription_${index}`
          }
        />
      </S.Price>
    );
  };

  return (
    <S.Container className={pageDesign}>
      <S.TubList boxDesignMobile={boxDesignMobile}>
        {tubs?.map((tub, index) => {
          const {
            title,
            subTitle,
            image,
            image2,
            imageHeight,
            showPrice = false,
            hideLabel = false,
            hideSubLabel = true,
            backgroundColor,
            badgeOtp,
            badgeSub,
            extraObjects,
            showTag,
            tagText
          } = tub;
          const activeTub = currentTubIndex === index ? 'current' : '';
          const hoverTub = tubIndex === index ? 'active' : '';
          const activeImage = tubIndex === index || currentTubIndex === index;

          const TubLabel = () => {
            if (hideLabel) return null;
            return <span>{title}</span>;
          };

          const TubSubLabel = () => {
            if (hideSubLabel) return null;
            return <span>{subTitle}</span>;
          };

          const BoxDesignInner = () => {
            return (
              <>
                <TubLabel />
                <TubSubLabel />
                <Price showPrice={showPrice} index={index}>
                  {currencySymbol}XX.xx
                </Price>
              </>
            );
          };

          const BoxDesign = () => {
            switch (boxDesign) {
              case 'wide':
                return (
                  <div>
                    <BoxDesignInner />
                  </div>
                );
              case 'normal':
              default:
                return <BoxDesignInner />;
            }
          };

          const handleClick = () => {
            setTubIndex(index);
            setCurrentTubIndex(index);
            setCurrentTub(tub);
            setCurrentProduct(
              currentCategory === `subscriptions`
                ? `subscription_${index}`
                : `onetime_${index}`
            );

            // set extraObjects
            if (extraObjects) {
              extraObjects.map(({ key, value }) => {
                if (!key) return false;
                const newExtraObjects = Object.assign(extraObjectsContext, {
                  [key]: value
                });
                return setExtraObjects(newExtraObjects);
              });
            }

            if (checkLocalStorage) {
              setCurrentTubIndex2(index);
            }
          };

          return (
            <S.TubListItem
              showChildren={showChildren}
              childCount={children?.length}
              key={index}
              className={`tub-list-item ${hoverTub} ${activeTub}`}
              onClick={handleClick}
              pageDesign={pageDesign}
              boxDesign={boxDesign}
              boxDesignMobile={boxDesignMobile}
              tubsLength={tubs.length}
            >
              {showChildren ? (
                <>{children[index]}</>
              ) : (
                <S.TubListItemContainer
                  imageHeight={imageHeight}
                  boxDesign={boxDesign}
                  boxDesignMobile={boxDesignMobile}
                  pageDesign={pageDesign}
                  backgroundColor={backgroundColor}
                >
                  {badgeOtp && (
                    <>
                      {currentCategory === `subscriptions` ? (
                        <S.Badge src={badgeSub} alt="" />
                      ) : (
                        <S.Badge src={badgeOtp} alt="" />
                      )}
                    </>
                  )}

                  <div>
                    {showTag && selectOnQueryIndex === index ? (
                      <S.Tag>{tagText}</S.Tag>
                    ) : (
                      <></>
                    )}

                    <TubImage
                      image={`${image}?width=100`}
                      image2={`${image2}?width=100`}
                      imageHeight={imageHeight}
                      active={activeImage}
                      alt={title}
                    />
                    <BoxDesign />
                    {showInnerChild && <>{children[index]}</>}
                  </div>
                </S.TubListItemContainer>
              )}
            </S.TubListItem>
          );
        })}
      </S.TubList>
    </S.Container>
  );
};

export default TubSelector;
